import { useState, useRef } from "react"

//Assets
import { Button, HookForm, HookInput, Input } from "components/anti"
import "react-phone-input-2/lib/material.css"
import Cookie from "js-cookie"

//Utils
import { useEffect } from "react"
import { useRouter } from "next/router"
import { v1 as uuidv1 } from "uuid"
import axios from "axios"
import { BottomSheet } from "react-spring-bottom-sheet"

import { useStore } from "lib/store"
import { MAIN_URL } from "lib"
import { NextSignup, UserCountry } from "lib/data-layer"
import Layout from "components/layout"
import { HeaderSection } from "components/pages/common/header-section"

// import { AsyncApollo, M_CHECK_NUMBER, EnumAuthCheck, M_SEND_OTP } from "lib"
// import { wording_general_error } from "components/content-wording"
// import SEO from "components/seo"
// import ReCAPTCHA from "react-google-recaptcha"

const HomePage = ({ countries }) => {
  // const [captchaEmpty, setCaptchaEmpty] = useState(false)
  // const [captchaToken, setCaptchaToken] = useState(null)

  const Router = useRouter()
  const resetState = Router?.query?.reset

  const setToast = useStore((state) => state.setToast)
  // const inputRef = useRef(null)
  //States ************************************************************************ //
  const [open, setOpen] = useState(false)

  const [countryList, setCountryList] = useState([])
  const [searchVal, setSearchVal] = useState("")
  const [countryCode, setCountryCode] = useState({
    code: "ID",
    dial_code: "+62",
    flag: "🇮🇩",
    name: "Indonesia",
  })
  //States ************************************************************************ //

  //Functions ********************************************************************* //

  const handleFoomaSubmit = async (values) => {
    const apiUrl = MAIN_URL("/api/fooma/auth/check-number")

    let { phone } = values

    if (phone[0] === "0" && countryCode.dial_code === "+62") {
      phone = phone.substring(1)
    }

    const phoneNumber = countryCode.dial_code + phone

    const uuid = uuidv1()

    Cookie.set(
      process.env.NEXT_PUBLIC_MEMBER_PHONE,
      JSON.stringify({
        phone,
        countryCode,
      }),
      { expires: 1 }
    )
    Cookie.set(process.env.NEXT_PUBLIC_UUID, JSON.stringify(uuid), {
      expires: 1,
    })

    const response = await axios.post(apiUrl, {
      id: phoneNumber,
    })

    if (response.data) {
      const type = response.data.type
      Cookie.set(process.env.NEXT_PUBLIC_NUMBER_TYPE, JSON.stringify(type), {
        expires: 1,
      })

      if (type === "REGISTER") {
        Router.push("/register")
      } else if (type === "LOGIN") {
        Router.push("/pin")
      }
    }
  }
  //Functions ********************************************************************* //

  //initiate State ********************************************************************* //
  useEffect(() => {
    // inputRef?.current?.focus()
    setCountryList(countries)

    if (resetState === "success") {
      setToast({
        isToast: true,
        toastState: {
          message: "Reset PIN Success! Try to login again.",
          status: "success",
        },
      })
    }
  }, [])

  useEffect(() => {
    const searchCountries = countries.filter(
      (el) => el.name.toLowerCase().indexOf(searchVal.toLocaleLowerCase()) >= 0
    )
    setCountryList(searchCountries)
  }, [searchVal])
  //initiate State ********************************************************************* //

  return (
    <>
      <Layout>
        <HeaderSection />
        <section className="sc-login-phone pb-main h-vh-100">
          <div className="container mw-sm">
            <header className="mb-4">
              <h2 className="text-uppercase anim fadeInUp">Login / Register</h2>
              <p className="anim fadeInUp d1">
                What’s your number? This number will be used the next time you
                login.
              </p>
            </header>
            <div className="info-wrapper mb-3">
              <i className="ail ai-info-circle pr-3" />
              <small className="info-text">
                You can use the same phone number if you already have an account
                at Fooma App
              </small>
            </div>
            <HookForm
              onSubmit={handleFoomaSubmit}
              name="form-login"
              defaultValues={{}}
              className="form-login"
            >
              {(props) => (
                <div>
                  <div className="row row-1 anim fadeInUp d2">
                    <div className="col-4 form-group">
                      <div
                        className="input-code-wrapper"
                        onClick={() => setOpen(true)}
                      >
                        {/* <p className="text-uppercase text-muted mb-0 label">
                        Country Code
                      </p> */}
                        <div className="input-code">
                          <span className="input-code-flag">
                            {countryCode.flag}
                          </span>
                          <span className="input-code-no">
                            {countryCode.dial_code}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-8 form-group">
                      <HookInput
                        as="input"
                        {...props}
                        floatingLabel
                        focus={"phone"}
                        id="phone"
                        name={"phone"}
                        label="Phone number"
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        min="0"
                        required
                        validation={{
                          pattern: {
                            value: /[0-9]+/g,
                            message: "Invalid mobile number",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="py-1 captcha-wrapper">
                    {/* <ReCAPTCHA
                      sitekey={process.env.NEXT_PUBLIC_CAPTCHA_KEY}
                      onChange={(value) => {
                        setCaptchaToken(value)
                      }}
                    /> */}
                  </div>
                  <div className="form-button mb-4 anim fadeInUp d3">
                    <div className="container mw-sm">
                      <Button
                        // variant={props.isValid ? "primary" : "muted"}
                        variant="primary"
                        className={`btn-block text-uppercase text-light w-100`}
                        // disabled={!props.isValid}
                      >
                        Continue
                      </Button>
                    </div>
                  </div>

                  {/* <div className="py-4">
                    <p className="disclaimer text-center">
                      Due to high traffic, there may be an interruption for OTP
                      for new user. If you’re affected, please wait and try
                      again later
                    </p>
                  </div> */}
                </div>
              )}
            </HookForm>
            {/* <div className="info-wrapper-2 mt-4">
              <small>
                There might be interruption to login due to high traffic. If you
                are affected, please try again later.
              </small>
            </div> */}
          </div>
        </section>
      </Layout>
      <BottomSheet
        open={open}
        onDismiss={() => setOpen(false)}
        snapPoints={({ minHeight, maxHeight }) => maxHeight}
        className="select-country-sheet"
      >
        <div className="header-sheet pt-4 pb-2">
          <div className="container mw-sm">
            <i
              className="ail ai-times back-button"
              onClick={() => setOpen(false)}
            />
            <h5 className="text-center text-uppercase">Select Country</h5>
            <Input
              variant="basic"
              className="search-input mt-4"
              placeholder="Search country"
              onChange={(e) => {
                setSearchVal(e.target.value)
              }}
              iconLeft
            />
          </div>
        </div>

        <div className="body-sheet">
          <div className="container mw-sm">
            <ul className="list-country">
              {countryList.map((item, i) => (
                <li
                  className="country-item"
                  onClick={() => {
                    UserCountry(item)
                    setCountryCode(item)
                    setOpen(false)
                    setSearchVal("")
                  }}
                  key={i}
                >
                  <div className="d-flex align-items-center">
                    <h2 className="flag pr-3 mb-0">{item.flag}</h2>
                    <p className="mb-0">{item.name}</p>
                  </div>
                  <p className="mb-0">{item.dial_code}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </BottomSheet>
    </>
  )
}

export default HomePage
