import TagManager from "react-gtm-module"

//!1 DONE
export const NextSignup = (values) => {
  let timeStamp = new Date()
  TagManager.dataLayer({
    dataLayer: {
      event: "nextSignup",
      parameter1: "page_location",
      value1: "/",
      parameter2: "button_name",
      value2: "NEXT REGISTER",
      parameter3: "button_category",
      value3: "Next Signup",
      parameter4: "timestamp",
      value4: `${timeStamp.getFullYear()}${
        timeStamp.getMonth() + 1
      }${timeStamp.getDate()}`,
    },
  })
}

//!2 DONE
export const UserCountry = (values) => {
  let { name } = values
  let timeStamp = new Date()
  let data = {
    event: "userCountry",
    parameter1: "page_locations",
    value1: "/",
    parameter2: "country_name",
    value2: name,
    parameter3: "timestamp",
    value3: `${timeStamp.getFullYear()}${
      timeStamp.getMonth() + 1
    }${timeStamp.getDate()}`,
  }
  TagManager.dataLayer({
    dataLayer: { ...data },
  })
}

//!3 DONE
export const UserGender = (values) => {
  let timeStamp = new Date()

  TagManager.dataLayer({
    dataLayer: {
      event: "userGender",
      parameter1: "page_location",
      value1: "/register",
      parameter2: "user_gender",
      value2: values,
      parameter3: "timestamp",
      value3: `${timeStamp.getFullYear()}${
        timeStamp.getMonth() + 1
      }${timeStamp.getDate()}`,
    },
  })
}

//!4 DONE
export const TermsConditionsClick = (values) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "Click",
      parameter1: "page_location",
      value1: "/register",
      parameter2: "link_domain",
      value2: values?.domain,
      parameter3: "link_text",
      value3: "/terms-conditions",
    },
  })
}

//!5 DONE
export const ResendOTP = (values) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "resendOTP",
      parameter1: "page_location",
      value1: values?.location,
      parameter2: "button_name",
      value2: "Resend",
      parameter3: "button_category",
      value3: "resend OTP",
    },
  })
}

//!6 DONE
export const ForgetPin = (values) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "forgetPin",
      parameter1: "page_location",
      value1: values?.location,
      parameter2: "button_text",
      value2: "forget pin",
    },
  })
}

//!7 DONE
export const ResendOTPFailed = (values) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "ResendOTPFailed",
      parameter1: "page_location",
      value1: values?.location,
      parameter2: "button_text",
      value2: "Resend OTP",
    },
  })
}

//!8 DONE
export const NextNewpin = (values) => {
  let tag = window.gtag
  TagManager?.dataLayer({
    dataLayer: {
      event: "nextNewpin",
      parameter1: "page_location",
      value1: values?.location,
      parameter2: "button_name",
      value2: "btn-next-create-pin",
      parameter3: "button_category",
      value3: "next new pin",
    },
  })
}

//!9 DONE
export const UserRegister = (values) => {
  let tag = window.gtag
  TagManager.dataLayer({
    dataLayer: {
      event: "UserRegister",
      parameter1: "page_location",
      value1: "/register",
      parameter2: "button_text",
      value2: "Register",
    },
  })
}

//!10 DONE
export const NextPIN = (values) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "nextPin",
      parameter1: "page_location",
      value1: values?.location,
      parameter2: "button_name",
      value2: "Next Pin",
      parameter3: "button_category",
      value3: "next new pin",
    },
  })
}

//!11 DONE
export const NextRetypepin = (values) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "nextRetypepin",
      parameter1: "page_location",
      value1: values?.location,
      parameter2: "button_name",
      value2: "create-pin-btn",
      parameter3: "button_category",
      value3: "next confirm new pin",
    },
  })
}

//!12
export const LoginSuccess = () => {
  let timeStamp = new Date()
  TagManager.dataLayer({
    dataLayer: {
      event: "login_date",
      login_date: `${timeStamp.getFullYear()}${
        timeStamp.getMonth() + 1
      }${timeStamp.getDate()}`,
    },
  })
}
