import { Link } from "components/anti"

export const HeaderSection = ({ backLink, helpBtn }) => {
  return (
    <div className="header-section py-3">
      <div className="container mw-sm">
        {backLink && (
          <Link to={backLink}>
            <i className="air ai-arrow-left" />
          </Link>
        )}
        {helpBtn && (
          <Link to="/get-help" className="link">
            Get Help
          </Link>
        )}
      </div>
    </div>
  )
}
