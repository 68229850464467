import React, { useEffect, useLayoutEffect, useContext } from "react"
// import { navigate } from "gatsby"
import { useRouter } from "next/router"
import { RotatingLines } from "react-loader-spinner"
import { useStore } from "lib/store"

// import { Navbar, Footer, Loader } from "components/anti"
// import { LoadingContext } from "context/loading-context"

const Layout = ({ children }) => {
  const router = useRouter()

  const navigate = (to) => {
    router.push(to)
  }

  // Loader Context
  // allows loader only shown when directly visited via URL
  // const { initialLoading } = useContext(LoadingContext)

  // Mobile viewport height workaround
  // prevent quirky behaviour on mobile when cover is set to 100vh
  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [])

  // Transition link
  // for smooth transition effect
  useEffect(() => {
    const wrapper = document.querySelector("main")
    wrapper.classList.add("animated", "fadeIn")
  }, [])
  useEffect(() => {
    const wrapper = document.querySelector("main")
    const anchor = document.querySelectorAll("a")
    anchor.forEach((item) => {
      const navigation = (e) => {
        const redirect = item.getAttribute("href")
        const currentLoc = window.location.pathname
        e.preventDefault()
        if (currentLoc !== redirect) {
          wrapper.classList.remove("fadeIn")
          wrapper.classList.add("fadeOut")
          setTimeout(() => {
            navigate(redirect)
          }, 250)
        }
      }
      if (item.getAttribute("href") && !item.getAttribute("target")) {
        item.addEventListener("click", navigation)
      }
    })
    return () => {
      anchor.forEach((item) => {
        const navigation = (e) => {
          const redirect = item.getAttribute("href")
          const currentLoc = window.location.pathname
          e.preventDefault()
          if (currentLoc !== redirect) {
            wrapper.classList.remove("fadeIn")
            wrapper.classList.add("fadeOut")
            setTimeout(() => {
              navigate(redirect)
            }, 250)
          }
        }
        if (item.getAttribute("href") && !item.getAttribute("target")) {
          item.removeEventListener("click", navigation)
        }
      })
    }
  }, [])

  const { loading_screen } = useStore((state) => state)

  return (
    <>
      <main>
        {/* LOADING SCREEN ************************** */}
        {loading_screen ? (
          <div className="loading-screen bg-dark">
            <RotatingLines
              strokeColor="#D2AE68"
              strokeWidth="5"
              animationDuration="0.75"
              width="50"
              visible={true}
            />
          </div>
        ) : (
          <>{children}</>
        )}
        {/* LOADING SCREEN ************************** */}
        {/* <>{children}</> */}
      </main>
    </>
  )
}

export default Layout
